/**
 * Simple component to be initialized for given DOM elements
 *
 * author: Marcel Frenz <info@marcelfrenz.de>
 */
class Component {
  constructor (element, observer) {
    /**
     * get options and its values for component
     * @param element
     */
    const getOptions = (element) => {
      const options = {};

      const capitalizeFirstLetter = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
      };

      Object.keys(element.dataset).forEach((option) => {
        const optionPrefix = 'option' + capitalizeFirstLetter(Component.prefix) + capitalizeFirstLetter(this.constructor.componentname.toLowerCase());

        if (option.indexOf(optionPrefix) > -1) {
          let optionValue = element.dataset[option];

          // handle json value when enclosed in {...}
          if (optionValue && optionValue !== '' && optionValue.indexOf('{') === 0 && optionValue.indexOf('}') === optionValue.length - 1) {
            optionValue = JSON.parse(optionValue);
          }

          if (optionValue === 'true') {
            optionValue = true;
          } else if (optionValue === 'false') {
            optionValue = false;
          }

          options[option.replace(optionPrefix, '').toLowerCase()] = optionValue;
        }
      });

      return options;
    };

    this.element = element;
    this.observer = observer;
    this.options = getOptions(element);

    /**
     * used for handline events
     *
     * instead of pulluting window use eventbus for given component
     *
     * @type {{messages: {}, on(*, *=): void, emit(*): void}}
     */
    this.eventbus = {
      messages: {},
      on (messages, callback) {
        messages.split(' ').forEach(message => {
          if (!this.messages[message]) {
            this.messages[message] = [];
          }
          if (typeof callback === 'function') {
            this.messages[message].push(callback);
          }
        });
      },
      emit (message) {
        if (this.messages[message]) {
          this.messages[message].forEach((func) => {
            func();
          });
        }
      }
    };

    this.uuid = Component.generateUuid;
  }

  /**
   * generate uuid to have unique id to each component instance
   *
   * @returns {string}
   */
  static get generateUuid () {
    function s4 () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  /**
   * use a prefix for initialization
   *
   * @returns {string}
   */
  static get prefix () {
    return 'cov';
  }

  /**
   * get prefixed component name
   *
   * @returns {string}
   */
  static get prefixedcomponentname () {
    return this.prefix + '-' + this.componentname.toLowerCase();
  }

  /**
   * get instances of same component
   *
   * @returns {T[]}
   */
  get siblings () {
    return this.observer.getInitializedComponents().filter(component => {
      return component !== this && component instanceof this.constructor;
    });
  }

  /**
   * set uuid to identify component from data-attribute
   *
   * @param {string} uuid
   */
  set uuid (uuid) {
    const prefix = Component.prefix;
    const name = this.constructor.componentname.toLowerCase();

    this.element.setAttribute('data-instance-' + prefix + '-' + name, uuid);
  }

  /**
   * get uuid from data-attribute
   *
   * @returns {string}
   */
  get uuid () {
    const prefix = Component.prefix;
    const name = this.constructor.componentname.toLowerCase();

    return this.element.getAttribute('data-instance-' + prefix + '-' + name);
  }

  /**
   * proxy function to resolve global component
   *
   * @param TargetComponent
   * @returns {*}
   */
  resolveGlobalComponent (TargetComponent, forceList) {
    return this.observer.resolveGlobalComponent(TargetComponent, forceList);
  }

  /**
   * proxy function to resolve global component
   *
   * @param TargetComponent
   * @returns {*}
   */
  resolveClosestComponents (TargetComponent, forceList) {
    return this.observer.resolveClosestComponents(TargetComponent, this.element, forceList);
  }

  /**
   * proxy function to resolve global component
   *
   * @param TargetComponent
   * @returns {*}
   */
  resolveInnerComponents (TargetComponent, forceList) {
    return this.observer.resolveInnerComponents(TargetComponent, this.element, forceList);
  }

  /**
   * proxy function to resolve global component
   *
   * @param TargetComponent
   * @returns {*}
   */
  resolveSiblingComponents (TargetComponent, forceList) {
    return this.observer.resolveSiblingComponents(TargetComponent, this.element, forceList);
  }
}

export default Component;
