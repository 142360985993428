import './index.scss';
import Component from '../component.js';
import ConfettiGenerator from 'confetti-js';

/**
 * Quiz
 *
 * author: Marcel Frenz <info@marcelfrenz.de>
 */
class Quiz extends Component {
  static get componentname () {
    return 'Quiz';
  }

  constructor (element, observer) {
    super(element, observer);
    const form = element.querySelector('form');
    const correct = form.querySelector('input[name="correct"]');
    const success = form.querySelector('input[name="success"]');
    let timeout = 0;

    if (location.pathname.indexOf('success') > -1) {
      try {
        var confettiSettings = {
          target: 'my-canvas'
        };
        var confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
      } catch (e) {}
    }

    form.addEventListener('submit', (e) => {
      const quiz = form.querySelector('[name="quiz"]:checked').value;

      e.preventDefault();

      if (`${quiz}` === `${correct.value}`) {
        location.href = `${success.value}-success.html`;
      } else {
        form.classList.add('error');

        window.clearTimeout(timeout);

        form.classList.add('pulsate');
        timeout = window.setTimeout(() => {
          form.classList.remove('pulsate');
        }, 150);
      }
    });
  }
}

export default Quiz;
