import ComponentObserver from './components/componentobserver.js';
import Base from './components/base/index';
import Header from './components/header/index';
import Stage from './components/stage/index';
import Intro from './components/intro/index';
import Quiz from './components/quiz/index';
import Footer from './components/footer/index';

/* eslint-disable no-new */
new ComponentObserver({
  Base,
  Header,
  Stage,
  Intro,
  Quiz,
  Footer
});
