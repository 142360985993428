import 'babel-polyfill';

/**
 * Simple component handler to initialize component instances for given DOM
 *
 * author: Marcel Frenz <info@marcelfrenz.de>
 *
 * Initialize component via data-init="PREFIX-COMPONENTNAME"
 *
 * Pass single option via data-option-PREFIX-COMPONENTNAME-OPTIONNAME="VALUE"
 * Pass options via data-options-PREFIX-COMPONENTNAME-OPTIONNAME='{"key": "VALUE"}'
 */
class ComponentObserver {
  constructor (components) {
    if (!ComponentObserver.instance) {
      this.initializedComponents = [];

      this.observe(Object.assign({}, components), (element, Component) => {
        /* eslint-disable no-new */
        const component = new Component(element, this);

        this.addInitializedComponent(component);
      });

      ComponentObserver.instance = this;
    }

    return ComponentObserver.instance;
  }

  /**
   * The observer method uses mutation observer to detect DOM structure to be initialized as components.
   * Therefore the mutated DOM is searched for elements with attribute data-init which will be initialized for given
   * component name when not already initialized. Multiple initializations for more than one component is possible and
   * valid.
   *
   * Furthermore the observer also detects removed DOM elements to handle component destruction.
   *
   * For browsers without mutation observer support, the dom is checked each 1000ms.
   *
   * @param components
   * @param fn
   */
  observe (components, fn) {
    /**
     * search for DOM elements to be initialized (either called initially without mutations or called by mutation
     * observer
     *
     * @param mutations
     */
    const search = (mutations) => {
      const add = (node) => {
        if (node.dataset && node.dataset.hasOwnProperty('init')) {
          // go through registered components and try to initialize it for given dom node
          for (const componentIndex in components) {
            const component = components[componentIndex];
            const uuid = node.getAttribute('data-instance-' + component.prefixedcomponentname);

            // only initialize not yet initialized components
            if (node.dataset.init.split(' ').indexOf(component.prefixedcomponentname) > -1) {
              if (uuid === null) {
                fn(node, component);
              }
            }
          }
        }
      };
      const remove = (node) => {
        if (node.dataset && node.dataset.hasOwnProperty('init')) {
          // go through registered components and try to remove initialization for given dom node
          for (const componentIndex in components) {
            const component = components[componentIndex];

            if (node.dataset.init.split(' ').indexOf(component.prefixedcomponentname) > -1) {
              const uuid = node.getAttribute('data-instance-' + component.prefixedcomponentname);

              this.removeInitializedComponentByUuid(uuid);
            }
          }
        }
      };

      // handle mutations by mutation observer
      if (mutations) {
        mutations.forEach(mutation => {
          const addedNodes = [].slice.call(mutation.addedNodes);
          const removedNodes = [].slice.call(mutation.removedNodes);

          // go through all added nodes which data-init attribute
          addedNodes.forEach(addedNode => {
            if (addedNode.tagName !== undefined) {
              [].slice.call(addedNode.querySelectorAll('[data-init]')).forEach(addedNestedNode => {
                add(addedNestedNode);
              });

              add(addedNode);
            }
          });

          removedNodes.forEach(removedNode => {
            if (removedNode.tagName !== undefined) {
              [].slice.call(removedNode.querySelectorAll('[data-init]')).forEach(removedNestedNode => {
                remove(removedNestedNode);
              });

              remove(removedNode);
            }
          });
        });
      } else {
        const initializedNodesToBeRemoved = [].slice.call(this.initializedComponents);

        // go through registered components and try to either initialize it
        for (const componentIndex in components) {
          const component = components[componentIndex];

          if (component.prefixedcomponentname !== undefined) {
            const elements = [].slice.call(window.document.querySelectorAll('[data-init~=' + component.prefixedcomponentname + ']'));

            elements.forEach(node => {
              const uuid = node.getAttribute('data-instance-' + component.prefixedcomponentname);

              /**
               * check for initialized nodes to be removed when not found any longer (check if node with uuid matches
               * initializedNode. If matched, remove initializedNode from initializedNodesToBeRemoved.
               */
              initializedNodesToBeRemoved.forEach((initializedNode, i) => {
                if (initializedNode.uuid === uuid) {
                  initializedNodesToBeRemoved.splice(i, 1);
                }
              });

              // only init when no uuid is given
              if (uuid === null) {
                fn(node, component);
              }
            });
          }
        }

        // remove all remaining nodes in initializedNodesToBeRemoved
        initializedNodesToBeRemoved.forEach(initializedNode => {
          this.removeInitializedComponentByUuid(initializedNode.uuid);
        });
      }
    };

    if (window.MutationObserver) {
      /* eslint-disable no-new */
      const observer = new MutationObserver(mutations => {
        search(mutations);
      });

      search();

      observer.observe(window.document.documentElement, {
        childList: true,
        subtree: true
      });
    } else {
      search();
    }
  }

  /**
   * add initialized component to array
   *
   * @param component
   */
  addInitializedComponent (component) {
    this.initializedComponents.push(component);
  }

  /**
   * remove initialized component from array
   * @param uuid
   */
  removeInitializedComponentByUuid (uuid) {
    const component = this.getInitializedComponentByUuid(uuid);

    if (component) {
      const index = this.initializedComponents.indexOf(component);

      if (index > -1) {
        this.initializedComponents.splice(index, 1);
      }
    }
  }

  /**
   * get initialized components by uuid (since initialized a uuid is generated for each component instance)
   *
   * @param uuid
   * @returns {number | * | BigInt | T}
   */
  getInitializedComponentByUuid (uuid) {
    return this.getInitializedComponents().find(component => {
      return component.uuid === uuid;
    });
  }

  /**
   * get initialized components for given component name
   *
   * @param name
   * @returns {*}
   */
  getInitializedComponentByName (name) {
    return this.getInitializedComponents().filter(component => {
      return component.constructor.componentname.toLowerCase() === name.toLowerCase();
    });
  }

  /**
   * get all initialized components
   *
   * @returns {Array}
   */
  getInitializedComponents () {
    return this.initializedComponents;
  }

  /**
   * retrieve initialized components from dom nodes
   *
   * @param TargetComponent
   * @param elements
   * @returns {*}
   */
  resolveComponent (TargetComponent, elements, forceList) {
    const components = [];

    elements.forEach(node => {
      const uuid = node.getAttribute('data-instance-' + TargetComponent.prefixedcomponentname);

      const component = this.getInitializedComponentByUuid(uuid);

      if (component) {
        components.push(component);
      }
    });

    if (components.length === 0) {
      if (forceList === true) {
        return [];
      }

      return null;
    } else if (components.length === 1) {
      if (forceList === true) {
        return components;
      }

      return components[0];
    } else {
      return components;
    }
  }

  resolveGlobalComponent (TargetComponent, forceList) {
    const elements = [].slice.call(document.body.querySelectorAll('[data-init~=' + TargetComponent.prefixedcomponentname + ']'));

    return this.resolveComponent(TargetComponent, elements, forceList);
  }

  resolveClosestComponents (TargetComponent, originComponentElement, forceList) {
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        let el = this;

        if (!document.documentElement.contains(el)) {
          return null;
        }

        do {
          if (el.matches(s)) {
            return el;
          }

          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);

        return null;
      };
    }

    const elements = originComponentElement.closest('[data-init~=' + TargetComponent.prefixedcomponentname + ']');

    if (elements) {
      return this.resolveComponent(TargetComponent, [elements], forceList);
    }

    return this.resolveComponent(TargetComponent, [], forceList);
  }

  resolveInnerComponents (TargetComponent, originComponentElement, forceList) {
    const elements = [].slice.call(originComponentElement.querySelectorAll('[data-init~=' + TargetComponent.prefixedcomponentname + ']'));

    return this.resolveComponent(TargetComponent, elements, forceList);
  }

  resolveSiblingComponents (TargetComponent, originComponentElement, forceList) {
    const elements = [].slice.call(originComponentElement.parentElement.querySelectorAll(':scope > [data-init~=' + TargetComponent.prefixedcomponentname + ']'));

    return this.resolveComponent(TargetComponent, elements, forceList);
  }
}

export default ComponentObserver;
