import './index.scss';
import Component from '../component.js';

/**
 * Header
 *
 * author: Marcel Frenz <info@marcelfrenz.de>
 */
class Header extends Component {
  static get componentname () {
    return 'Header';
  }

  constructor (element, observer) {
    super(element, observer);

    this.show = false;

    window.addEventListener('scroll', () => {
      const top = document.body.getBoundingClientRect().top;

      if (top === 0) {
        this.show = true;
      } else if (top > 0) {
        this.show = false;
      } else {
        if (top > this.scrollPos || top > -73) {
          this.show = true;
        } else {
          this.show = false;
        }
      }

      if (this.show) {
        this.element.classList.remove('hide');
      } else {
        this.element.classList.add('hide');
      }

      this.scrollPos = (document.body.getBoundingClientRect()).top;
    }, {
      capture: true,
      passive: true
    });
  }
}

export default Header;
